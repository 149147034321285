import RequestService from 'src/shared/services/RequestService';
import localeUtils from 'src/shared/utils/localeUtils';

const getServerTime = () => {
    const url = `${window.location.origin}/api/time`;
    const config = {
        headers: { 'Accept-Language': localeUtils.getLocaleForServer() },
    };
    return RequestService.get(url, config)
        .then((res) => res.data.data)
        .catch((err) => console.log(err));
};

const ServerAPI = {
    getServerTime,
};

export default ServerAPI;
