import React, { useEffect } from 'react';
import withConnect from 'src/shared/utils/withConnect';
import common from 'src/shared/utils/common';
import useRouter from 'src/shared/hooks/useRouter';
import RoutePaths from 'src/config/RoutePaths';
import UserStatus from 'src/shared/config/UserStatus';
import ExpireService from 'src/shared/services/ExpireService';

const withNoAuth = (params = { isPage: false }) => (WrappedComponent) => {
    const Component = (props) => {
        const router = useRouter();
        useEffect(() => {
            const { memberInfo } = props;
            // @TODO call api

            if (params.isPage) {
                if (memberInfo && memberInfo.status) {
                    switch (memberInfo.status) {
                        case UserStatus.PENDING:
                            router.replacePath(RoutePaths.LOGIN);
                            break;
                        case UserStatus.APPROVED:
                            if (memberInfo.token && memberInfo.expireDate && !ExpireService.checkIsExpire(memberInfo.expireDate)) {
                                if (router.query && router.query.redirectTo) {
                                    const { redirectTo, ...others } = router.query;
                                    router.replacePath(redirectTo, others);
                                    return;
                                }
                                router.replacePath(RoutePaths.INVESTMENT);
                            }
                            break;
                        default:
                            break;
                    }
                }
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [props.memberInfo, props.memberInfo.token]);

        return <WrappedComponent {...props} />;
    };

    const mapStateToProps = (state) => common.processMemberInfo(state);

    return withConnect(mapStateToProps)(Component);
};

export default withNoAuth;
