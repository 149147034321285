import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';
import Image from 'src/shared/components/Image';
import RoundedButton from 'src/shared/components/RoundedButton';
import RoutePaths from 'src/config/RoutePaths';
import UserStatus from 'src/shared/config/UserStatus';
import { withTranslation } from 'next-i18next';
import { compose } from 'redux';

class ReviewProfile extends React.PureComponent {
    render() {
        const { className, image, type, status, t } = this.props;

        if (status === UserStatus.REJECTED) {
            return (
                <div className={`${styles.layer} ${className}`}>
                    <div className={styles.body}>
                        <p className={styles.title}>{t('msg_profile_rejected')}</p>
                        {/* <p className={styles.content}>Our Product Specialist will contact you shortly. </p> */}
                        <RoundedButton type={type} href={RoutePaths.HOME}>
                            {t('button_back_to_home')}
                        </RoundedButton>
                    </div>
                </div>
            );
        }

        return (
            <div className={`${styles.layer} ${className}`}>
                <Image width="37px" className={styles.image} src={image} />
                <div className={styles.body}>
                    <p className={styles.title}>{t('msg_profile_reviewing')}</p>
                    <p className={styles.content}>{t('msg_contact_you_shortly')}</p>
                    <RoundedButton type={type} href={RoutePaths.HOME}>
                        {t('button_back_to_home')}
                    </RoundedButton>
                </div>
            </div>
        );
    }
}

ReviewProfile.defaultProps = {
    status: '',
    className: '',
    image: '/static/images/svg/ThankYou(Icon)_white@3x.svg',
    type: 'brown',
};

ReviewProfile.propTypes = {
    status: PropTypes.string,
    className: PropTypes.string,
    image: PropTypes.string,
    type: PropTypes.oneOf(['brown', 'white', 'black']),
};

export default compose(withTranslation())(ReviewProfile);
